<template>
  <div>
 
  <b-row>
    <b-col  md="12" > 
      <b-card>
        <b-row>
          <b-col cols="12" xl="4" class="d-flex justify-content-between flex-column">
            <div class="d-flex justify-content-start">
              <b-avatar
                :src="userData.photo"
                :text="avatarText(userData.nom)"
                variant="light-success"
                size="104px"
                rounded />
              <div class="d-flex flex-column ml-1">
                <div class="mb-1">
                  <h4 class="mb-0"> {{ userData.nom }} </h4>
                  <span class="card-text">{{ userData.email }}</span>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center mt-2">
              <div class="d-flex align-items-center mr-2">
                <b-avatar variant="light-primary" rounded>
                  <feather-icon
                    icon="DollarSignIcon"
                    size="18"
                  />
                </b-avatar>
                <div class="ml-1">
                  <h5 class="mb-0">
                    {{ userData.solde }}
                  </h5>
                  <small>Solde</small>
                </div>
              </div>

              <div class="d-flex align-items-center">
                <b-avatar variant="light-success" rounded >
                  <feather-icon
                    icon="TrendingUpIcon"
                    size="18"
                  />
                </b-avatar>
                <div class="ml-1">
                  <h5 class="mb-0">
                    {{ userData.solde_free_contact }}
                  </h5>
                  <small>Contact gratuit</small>
                </div>
              </div>
            </div>
          </b-col>

          <b-col cols="12" xl="4">
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Nom</span>
                </th>
                <td class="pb-50">
                  {{ userData.nom }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Status</span>
                </th>
                <td class="pb-50 text-capitalize">
                  <b-badge pill variant="light-success" class="text-capitalize" v-if="userData.actif">Active</b-badge>
                  <b-badge pill variant="light-secondary" class="text-capitalize" v-else>Inactive</b-badge>
                </td>
              </tr>
         
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="MailIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Email</span>
                </th>
                <td class="pb-50">
                  {{ userData.email }}
                </td>
              </tr>
              <tr>
                <th>
                  <feather-icon
                    icon="PhoneIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Tél</span>
                </th>
                <td>
                  {{ userData.tel }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="MapPinIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Adresse</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ userData.address }}, {{ userData.zipcode }} {{ userData.ville }}
                </td>
              </tr>
            </table>
          </b-col>
          
          <b-col cols="12" xl="4">
            <table class="mt-2 mt-xl-0 w-100">
              
              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold">Services</span>
                </th>
                <td class="pb-50 text-capitalize">
                  <ul id="services" class="list-unstyled my-1">
                    <li v-for="service in userData.services">
                      <b-badge variant="light-primary">{{ service.name}}</b-badge>
                    </li>
                  
                  </ul>
                </td>
              </tr>
         
              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold">Abonnement</span>
                </th>
                <td class="pb-50">
                  <b-badge variant="light-primary" v-if="userData.current_abonnement">{{ userData.current_abonnement.type }}</b-badge>
                </td>
              </tr>

              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold">Horaires</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ userData.horaires_text }}
                </td>
              </tr>

              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold">Urgence</span>
                </th>
                <td class="pb-50 text-capitalize">
                  <b-badge pill variant="light-success" class="text-capitalize" v-if="userData.urgence_repond">Oui</b-badge>
                  <b-badge pill variant="light-secondary" class="text-capitalize" v-else>Non</b-badge>
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold">Afficher sur site</span>
                </th>
                <td class="pb-50 text-capitalize">
                  <b-badge pill variant="light-success" class="text-capitalize" v-if="userData.show_in_site">Oui</b-badge>
                  <b-badge pill variant="light-secondary" class="text-capitalize" v-else>Non</b-badge>
                </td>
              </tr>

              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold">Région</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ userData.region_text }}
                </td>
              </tr>
            
              
            </table>
          </b-col>
        </b-row>
      </b-card>

    </b-col>
  
  </b-row>
  <b-row>
    <b-col md="6">
      <b-card no-body >
        <b-card-body>
          <b-card-title>Historiques des recharges</b-card-title>
        </b-card-body>
        <b-table
          class="position-relative"
          responsive
          :fields="rechargeColumns"
          :items="userData.recharge_historiques"
          :current-page="rechargeHistoriquesCurrentPage"
          :per-page="rechargeHistoriquesPerPage"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
        >

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
  
            <!-- Pagination -->
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
  
              <b-pagination
                v-model="rechargeHistoriquesCurrentPage"
                :total-rows="totalRechargeHistoriques"
                :per-page="rechargeHistoriquesPerPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
  
            </b-col>
  
          </b-row>
        </div>
      </b-card> 
    </b-col>
    <b-col md="6">
      <b-card no-body >
        <b-card-body>
          <b-card-title>Historiques d'abonnements</b-card-title>
        </b-card-body>
        <b-table
          class="position-relative"
          responsive
          :fields="abonnementColumns"
          :items="userData.abonnement_historiques"
          :current-page="abonnementHistoriquesCurrentPage"
          :per-page="abonnementHistoriquesPerPage"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
        >

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
  
            <!-- Pagination -->
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
  
              <b-pagination
                v-model="abonnementHistoriquesCurrentPage"
                :total-rows="totalAbonnementHistoriquesPerPage"
                :per-page="abonnementHistoriquesPerPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
  
            </b-col>
  
          </b-row>
        </div>
      </b-card> 
    </b-col>
  </b-row>

  <b-row>
    <b-col md="6">
      <b-card>

        <h5 class="mb-0">
          Travaux réalisés
        </h5>
        <hr/>

        <b-row>
          <b-col
            v-for="photo in userData.photos"
            md="2"
            cols="12"
            class="profile-latest-img"
          >
            <b-avatar
              :src="photo.src"
              variant="light-success"
              size="104px"
              rounded />
          </b-col>
          <!-- latest photo loop -->
        </b-row>

       
        
    </b-card> 
  </b-col>
</b-row>
    

</div>
</template>


<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput,BFormRadioGroup, BFormCheckboxGroup, BForm, BTable, BCard, BCardHeader, BCardBody, BBadge, BCardTitle, BFormCheckbox, BPagination
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import { quillEditor } from 'vue-quill-editor'

import { mapActions, mapGetters } from 'vuex'

export default {
    components: {
        BButton,  BMedia,  BAvatar,  BRow,  BCol, BFormGroup, BFormInput, BFormCheckboxGroup, BForm, BTable, BCard, BCardHeader, BCardBody, BBadge, BCardTitle, 
        BFormCheckbox, BFormRadioGroup,  quillEditor, BPagination
        
    },
    props: ['id'],

    data() {
        return {

            userData: {
                id: null,
                nom: null,
                tel: null,
                email: null,
                photo: null,
                solde: null,
                solde_free_contact: null,
                description: null,
                address: null,
                zipcode: null,
                map_lat: null,
                map_long: null,
                region_text: null,
                horaires_text: null,
                urgence_repond: null,
                ville: null,
                abonnement_id: null,
                abonnement: null,
                actif: null,
                services: null,
                free_contact_nbrs: null,
                show_email: null,
                show_tel: null,
                recharge_historiques: null,
                current_abonnement: null,
                abonnement_historiques: null,
                photos: null,
                show_in_site: null
                
            },

            rechargeColumns : [
                { label: 'Date recharge', key: 'date', sortable: true },
                { label: 'Montant', key: 'montant', sortable: true }
            ],

            abonnementColumns : [
                { label: 'Date debut', key: 'debut', sortable: true },
                { label: 'Date fin', key: 'fin', sortable: true },
                { label: 'Type', key: 'type', sortable: true }
            ],

            avatarText,

            urgenceOptions : [
                { text: 'Oui', value: 1 },
                { text: 'Non', value: 0 },
            ],

            actifOptions : [
                { text: 'Oui', value: 1 },
                { text: 'Non', value: 0 },
            ],

            snowOption: {
                theme: 'snow',
            },

            inputImageRenderer:null,

            refInputEl: null,

            
            totalRechargeHistoriques : 0,
            rechargeHistoriquesPerPage : 5,
            rechargeHistoriquesCurrentPage : 1,

            totalAbonnementHistoriquesPerPage : 0,
            abonnementHistoriquesPerPage : 5,
            abonnementHistoriquesCurrentPage : 1,


      }
    },

    computed: {
  

    },
    methods: {
        ...mapActions('artisans', {'getArtisan':'getItem'}),

        editData(id) {
            this.$router.push(`/artisan/edit/${id}`);
        },

    },


    created() {


         if(this.id) {
            this.getArtisan(this.id).then(result => {
                let artisan = result.data

                this.userData = {
                  id: artisan.id,
                  nom: artisan.nom,
                  tel: artisan.tel,
                  email: artisan.email,
                  photo: artisan.photo,
                  solde: artisan.solde,
                  solde_free_contact: artisan.solde_free_contact,
                  description: artisan.description,
                  address: artisan.address,
                  zipcode: artisan.zipcode,
                  map_lat: artisan.map_lat,
                  map_long: artisan.map_long,
                  region_text: artisan.region_text,
                  horaires_text: artisan.horaires_text,
                  urgence_repond: artisan.urgence_repond,
                  ville: artisan.ville,
                  abonnement_id: artisan.abonnement_id,
                  abonnement: artisan.abonnement,
                  actif: artisan.actif,
                  services: artisan.services,
                  free_contact_nbrs: artisan.free_contact_nbrs,
                  show_email: artisan.show_email,
                  show_tel: artisan.show_tel,
                  recharge_historiques: artisan.recharge_historiques,
                  abonnement_historiques: artisan.abonnement_historiques,
                  current_abonnement: artisan.current_abonnement,
                  photos: artisan.photos,
                  show_in_site: artisan.show_in_site
                }

                this.totalRechargeHistoriques = artisan.recharge_historiques.length
                this.totalAbonnementHistoriquesPerPage = artisan.abonnement_historiques.length
          })
        }
    },

    watch: {

        id(val) {
            if(!val) {
                this.userData = {
                    id: null,
                    nom: null,
                    tel: null,
                    email: null,
                    photo: null,
                    solde: null,
                    solde_free_contact: null,
                    description: null,
                    address: null,
                    zipcode: null,
                    map_lat: null,
                    map_long: null,
                    region_text: null,
                    horaires_text: null,
                    urgence_repond: null,
                    ville: null,
                    abonnement_id: null,
                    abonnement: null,
                    actif: null,
                    services: null,
                    free_contact_nbrs: null,
                    show_email: null,
                    show_tel: null,
                    recharge_historiques: null,
                    abonnement_historiques: null,
                    photos: null,
                }
            }
        }

    }

}
</script>

<style>
    .v-select:not(.vs--single) .vs__deselect svg {
        fill: #fff;
    }

    #services {
        margin: 0rem;
    }

    #services li {
      display: inline;
    }

    #services li:not(:first-child) .badge {
      margin-left: 1rem;
    }

    .card .card-header{
      justify-content: flex-start !important;
    }

    

    .b-avatar-group-inner{
      padding: 1rem;
    }

    [dir] .card .card-title{
      margin-bottom: 0px;
    }

 
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
